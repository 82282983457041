import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {User} from '../../../core/models/user/user';
import {AsyncPipe} from '@angular/common';

@Component({
    selector: 'app-document-scans',
    templateUrl: './document-scans.component.html',
    styleUrls: ['./document-scans.component.scss']
})
export class DocumentScansComponent implements OnInit {
    public user: User = new User();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DocumentScansComponent>) {
    }

    ngOnInit() {
        this.user = this.data.user;
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}

import {Injectable} from '@angular/core';
import {Router, CanLoad} from '@angular/router';
import {IS_SUPER} from '../../shared/constants/config';


@Injectable({providedIn: 'root'})
export class SuperAdminGuard implements CanLoad {
    constructor(private router: Router) {
    }

    public canLoad(): boolean {
        if (localStorage.getItem(IS_SUPER) === 'superAdmin') {
            return true;
        } else if (localStorage.getItem(IS_SUPER) === 'admin') {
            this.router.navigate(['admin']);
            return false;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
}

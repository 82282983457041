import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '../components/header/header.component';
import {MaterialModule} from './material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SideNavComponent} from '../components/side-nav/side-nav.component';
import {RouterModule} from '@angular/router';
import {CurrencyTransactionsComponent} from './dialogs/currency-transactions/currency-transactions.component';
import {WithdrawalDataComponent} from './dialogs/currency-transactions/withdrawal-data/withdrawal-data.component';
import {DepositDataComponent} from './dialogs/currency-transactions/deposit-data/deposit-data.component';
import {DocumentScansComponent} from './dialogs/document-scans/document-scans.component';
import {PaginationComponent} from '../components/pagination/pagination.component';
import {LoaderComponent} from '../components/loader/loader.component';
import {ConvertOffsetToPageIndexPipe} from '../core/pipes/convert-offset-to-page-index.pipe';
import {CheckingForEmptyValuePipe} from '../core/pipes/checking-for-empty-value.pipe';
import {DatePeriodComponent} from '../components/date-period/date-period.component';
import {AuthImagePipe} from '../core/pipes/auth-image.pipe';
import {CustomCurrencyPipePipe} from '../core/pipes/custom-currency-pipe.pipe';
import {NgxCurrencyModule} from 'ngx-currency';
import {RoundUpValuePipe} from '../core/pipes/round-up-value.pipe';
import {SearchComponent} from '../components/search/search.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NoDataComponent} from '../components/no-data/no-data.component';
import {HelpMessageToCheckValueComponent} from '../components/help-message-to-check-value/help-message-to-check-value.component';
import {ChangeValuePipe} from '../core/pipes/change-value.pipe';
import {NotificationComponent} from '../components/notification/notification.component';
import {WelcomeComponent} from '../components/welcome/welcome.component';

export const customCurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 0,
    prefix: '',
    suffix: ' $',
    thousands: ' ',
    nullable: false
};

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        HeaderComponent,
        SideNavComponent,
        CurrencyTransactionsComponent,
        WithdrawalDataComponent,
        DepositDataComponent,
        DocumentScansComponent,
        PaginationComponent,
        LoaderComponent,
        DatePeriodComponent,
        SearchComponent,
        NoDataComponent,
        HelpMessageToCheckValueComponent,
        NotificationComponent,
        WelcomeComponent,

        // pipes
        ConvertOffsetToPageIndexPipe,
        CheckingForEmptyValuePipe,
        AuthImagePipe,
        CustomCurrencyPipePipe,
        RoundUpValuePipe,
        ChangeValuePipe
    ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        PaginationComponent,
        LoaderComponent,
        DatePeriodComponent,
        SearchComponent,
        NoDataComponent,
        HelpMessageToCheckValueComponent,
        NotificationComponent,
        WelcomeComponent,

        // modules
        MaterialModule,
        NgxCurrencyModule,
        ReactiveFormsModule,
        FormsModule,

        // pipes
        ConvertOffsetToPageIndexPipe,
        CheckingForEmptyValuePipe,
        AuthImagePipe,
        CustomCurrencyPipePipe,
        RoundUpValuePipe,
        ChangeValuePipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class SharedModule {
}

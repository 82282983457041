import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {IS_SUPER} from '../../shared/constants/config';
import {ValidationUtils} from '../../shared/constants/validation-utils';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public submitted: boolean = false;
    public error: string = null;

    constructor(
        private route: ActivatedRoute,
        private builder: FormBuilder,
        private router: Router,
        private authService: AuthService,
    ) {
    }

    public ngOnInit(): void {
        if (this.authService.loggedIn) {
            localStorage.getItem(IS_SUPER) === 'superAdmin' ? this.router.navigate(['super-admin/manage-admins'])
                : this.router.navigate(['admin']);
        }

        this.loginForm = this.builder.group({
            email: ['', [Validators.required, Validators.pattern(ValidationUtils.EMAIL_VALIDATION)]],
            password: ['', Validators.required]
        });
    }

    public submitForm(): void {
        this.submitted = true;
        if (this.loginForm.valid) {
            this.authService.login(this.loginForm.getRawValue()).subscribe((res) => {
                    localStorage.getItem(IS_SUPER) === 'superAdmin' ? this.router.navigate(['super-admin/manage-admins'])
                        : this.router.navigate(['admin']);
                }, err => {
                    this.error = err.error.message;
                }
            );
        }
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'customCurrencyPipe'
})
export class CustomCurrencyPipePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const price = value.toString().slice(0, value.toString().length);
            return price.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') + '$';
        } else {
            return '-';
        }
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-currency-transactions',
    templateUrl: './currency-transactions.component.html',
    styleUrls: ['./currency-transactions.component.scss']
})
export class CurrencyTransactionsComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CurrencyTransactionsComponent>,
    ) {
    }

    ngOnInit() {
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _textMessage: string;
    private _type: string;
    private _showNotify: boolean = false;

    constructor() {
    }

    public showMessage(type, message, delay = 4500): void {
        this._showNotify = true;
        this._type = type;
        if (message && message.error) {
            this.showErrors(message.error);
        } else {
            this._textMessage = message;
        }
        this.hideMessage(delay);
    }

    private hideMessage(delay): void {
        setTimeout(() => this._showNotify = false, delay);
    }

    private showErrors(error): void {
        for (const key in error) {
            if (error.hasOwnProperty(key)) {
                this._textMessage = `${error[key]}`;
            }
        }
    }

    get textMessage(): string {
        return this._textMessage;
    }

    set textMessage(value: string) {
        this._textMessage = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get showNotify(): boolean {
        return this._showNotify;
    }

    set showNotify(value: boolean) {
        this._showNotify = value;
    }
}

import {SortDirection} from '@angular/material';

export class Pagination {
    count: number;
    limit: number;
    offset: number;
    queryString?: string;
    sortField?: string;
    directionOfSort?: SortDirection | any;
    startDate?: string;
    endDate?: string;
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'convertOffsetToPageIndex'
})
export class ConvertOffsetToPageIndexPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return value ? value / value : 0;
    }
}

import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Pagination} from '../core/models/pagination';
import {BehaviorSubject} from 'rxjs';
import {Permission} from '../core/models/permission/permission';
import {PERMISSIONS} from '../shared/constants/config';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public updateSearchValue = new BehaviorSubject({update: false});
    private _adminPermissions: Permission[];

    constructor(
        private route: ActivatedRoute
    ) {
        this._adminPermissions = JSON.parse(localStorage.getItem(PERMISSIONS));
    }

    public getQueryParams(): {} {
        let queryParams: any;
        this.route.queryParams.subscribe(map => map);
        queryParams = this.route.snapshot.queryParams;
        if (queryParams) {
            return queryParams;
        }
    }

    public defaultPagination(): Pagination {
        return {
            count: 100,
            limit: 10,
            offset: 0
        };
    }

    get adminPermissions(): Permission[] {
        return this._adminPermissions;
    }

    set adminPermissions(value: Permission[]) {
        this._adminPermissions = value;
    }

    public checkPermission(permissionName: string): boolean {
        if (this._adminPermissions) {
            let permission: Permission = new Permission();
            this._adminPermissions.forEach(item => {
                if (item.permission === permissionName) {
                    permission = item;
                }
            });
            return permission && permission.set;
        }
    }

    public getAvailablePermissions(): Permission[] {
        if (this._adminPermissions) {
            const permission: Permission[] = [];
            this._adminPermissions.forEach(item => {
                if (item.set === true) {
                    permission.push(item);
                }
            });
            return permission;
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Deposit} from '../../../../core/models/currency-transactions/deposit';

@Component({
    selector: 'app-deposit-data',
    templateUrl: './deposit-data.component.html',
    styleUrls: ['./deposit-data.component.scss']
})
export class DepositDataComponent implements OnInit {
    @Input() public deposits: Deposit[];
    public columnsToDisplay = ['id', 'status', 'biteeuFee', 'address', 'quantity', 'createdAt', 'updatedAt'];

    constructor() {
    }

    public ngOnInit(): void {
    }
}

import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isLoggedIn: boolean;

    constructor(private auth: AuthService) {
    }

    ngOnInit(): void {
        this.isLoggedIn = this.auth.loggedIn;
    }

    public logout(): void {
        this.auth.logout();
    }
}

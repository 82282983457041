import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './core/guard/auth.guard';
import {LoginComponent} from './modules/login/login.component';
import {SuperAdminGuard} from './core/guard/super-admin.guard';
import {AdminCanLoadGuard} from './core/guard/admin-can-load.guard';
import {MainPageComponent} from './components/main-page/main-page.component';

const appRoutes: Routes = [
    {
        path: '',
        component: MainPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'super-admin',
                canLoad: [SuperAdminGuard],
                loadChildren: () => import('./modules/superadmin/super-admin.module').then(m => m.SuperAdminModule)
            },
            {
                path: 'admin',
                canLoad: [AdminCanLoadGuard],
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
            },
        ]
    },
    {
        path: '404',
        loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

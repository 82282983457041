import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Pagination} from '../../core/models/pagination';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @Input() paginationData: Pagination = new Pagination();
    @Input() pageIndex: number;
    @Input() updateSubject: any;

    constructor() {
    }

    ngOnInit() {
        if (!this.paginationData) {
            this.paginationData = {
                limit: 10,
                offset: 0,
                count: 100,
            };
        }
    }

    public changePage(pageInfo: any): void {
        this.paginationData.limit = pageInfo.pageSize;
        if (pageInfo.pageIndex === 0) {
            this.paginationData.offset = pageInfo.pageIndex;
        } else {
            this.paginationData.offset = pageInfo.pageIndex * pageInfo.pageSize;
        }
        if (this.updateSubject) {
            this.updateSubject.next({update: true});
        }
    }
}

import {Injectable, Injector} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpEvent, HttpResponse
} from '@angular/common/http';

import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {JwtService} from '../auth/jwt.service';
import {throwError} from 'rxjs';
import {Router} from '@angular/router';
import {LoaderService} from '../loader.service';
import {IS_SUPER} from '../../shared/constants/config';
import {NotificationsService} from '../notification.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private injector: Injector,
        private jwtService: JwtService,
        private router: Router,
        private loaderService: LoaderService,
        public notificationsService: NotificationsService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.showLoader();

        this.authService = this.injector.get(AuthService);
        const token = this.jwtService.getToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (request.headers.has('custom-header')) {
                this.loaderService.hideLoader();
            }
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loaderService.hideLoader();
                }
                return event;
            }),
            catchError((error: any) => {
                this.loaderService.hideLoader();
                this.loaderService.endedAllRequests = false;
                this.notificationsService.showMessage('error', error);
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.jwtService.deleteToken();
                    localStorage.removeItem(IS_SUPER);
                    this.router.navigate(['login']);
                }
                return throwError(error);
            }));
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Withdrawal} from '../../../../core/models/currency-transactions/withdrawal';

@Component({
    selector: 'app-withdrawal-data',
    templateUrl: './withdrawal-data.component.html',
    styleUrls: ['./withdrawal-data.component.scss']
})
export class WithdrawalDataComponent implements OnInit {
    @Input() public withdrawals: Withdrawal[];
    public columnsToDisplay = ['id', 'status', 'biteeuFee', 'address', 'quantity', 'createdAt', 'updatedAt'];

    constructor() {
    }

    ngOnInit() {
    }
}

export class User {
    id: number;
    email: string;
    emailChecked: boolean;
    firstName: string;
    lastName: string;
    phone: string;
    birthday: string;
    countryId: number;
    state: string;
    city: string;
    address: string;
    apt: string;
    zip: string;
    kycVerified: boolean;
    cookiesAgreed: boolean;
    isGdpr: boolean;
    totpCode: string;
    totpPeriod: number;
    createdAt: string;
    updatedAt: string;
    twoFaEnabled: boolean;
    emailVerificationEnabled: boolean;
    withdrawalsMailEnable: boolean;
    isVerified: boolean;
    subaccountUuid: string;
    residenceCountryId: number;
    isUpdated: boolean;
    verifiedBy: string;
    country: Country;
    residenceCountry: Country;
    kyc: Kyc;
    customFees: CustomFees;
    passport: Passport;
}

export class Country {
    id: number;
    name: string;
    code: string;
}

export class Kyc {
    userId: string;
    selfieUrl: string;
    passportUrl: string;
    isUploaded: boolean;
    status: number;
    code: string;
    error: string;
    createdAt: string;
    updatedAt: string;
}

export class CustomFees {
    id: string;
    userId: string;
    maxWithdrawal: string;
    depositFee: string;
    orderFee: string;
    withdrawalFee: string;
    createdAt: string;
    updatedAt: string;
    identifiedLimit: number;
    verifiedLimit: number;
}

export class Passport {
    id: string;
    userId: string;
    passportNumber: string;
    issuedBy: string;
    issueDate: string;
    expiryDate: string;
    createdAt: string;
    updatedAt: string;
}

import {Subject} from 'rxjs';
import {OnDestroy} from '@angular/core';

export class UnSubscribeComponent implements OnDestroy {
    protected unsubscribe$: Subject<boolean> = new Subject<boolean>();

    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.unsubscribe();
    }
}

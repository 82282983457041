import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-date-period',
    templateUrl: './date-period.component.html',
    styleUrls: ['./date-period.component.scss'],
})
export class DatePeriodComponent implements OnInit {
    @Input() dateFilterData;
    @Output() dateFilter = new EventEmitter<string[]>();
    public fromDate: string;
    public toDate: string;
    public period: string[] = [];

    constructor() {
    }

    ngOnInit() {
        this.getDateFromQuery(this.dateFilterData);
    }

    public fromDateChange(dateFrom: any): void {
        this.fromDate = moment(dateFrom).format('YYYY-MM-DD');
        this.getPeriod();
    }

    public toDateChange(dateTo: any): void {
        this.toDate = moment(dateTo).format('YYYY-MM-DD');
        this.getPeriod();
    }

    public getPeriod(): void {
        if (this.fromDate && this.toDate) {
            this.period[0] = this.fromDate;
            this.period[1] = this.toDate;
            this.dateFilter.emit(this.period);
        }
    }

    public resetPeriod(): void {
        this.fromDate = null;
        this.toDate = null;
        this.period = [];
        this.dateFilter.emit(null);
    }

    private getDateFromQuery(date): void {
        if (date && typeof date === 'string' && date.length) {
            const query = decodeURIComponent(date);
            const dateFilterField = query.split('<')[1];
            const getDate = query.split('<' + dateFilterField + '<');
            this.fromDate = getDate[0];
            this.toDate = getDate[1];
        } else if (date && date.startDate && date.endDate) {
            this.fromDate = date.startDate ;
            this.toDate = date.endDate;
        }
    }
}

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    public loader: boolean;
    public endedAllRequests: boolean;

    constructor() {
    }

    showLoader() {
        this.loader = true;
    }

    hideLoader() {
        this.loader = false;
    }
}

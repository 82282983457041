import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {UnSubscribeComponent} from '../../shared/unsubscribe-component';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends UnSubscribeComponent implements OnInit {
    @Input() searchPlaceholder: string;
    @Input() searchValue: string | number;
    @Output() searchValueOutput = new EventEmitter<string>();
    public searchFormControl: FormControl = new FormControl();

    constructor(
        private commonsService: CommonService,
    ) {
        super();
    }

    ngOnInit() {
        this.commonsService.updateSearchValue.pipe(
            takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res.update) {
                    this.searchFormControl.setValue(null);
                } else {
                    this.searchFormControl.setValue(this.searchValue);
                }
            });
    }

    public searchData(value: string) {
        if (value.length) {
            this.searchValueOutput.emit(this.searchFormControl.value);
        }
    }
}

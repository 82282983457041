import {Component, Input, OnChanges} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
    selector: 'app-help-message-to-check-value',
    templateUrl: './help-message-to-check-value.component.html',
    styleUrls: ['./help-message-to-check-value.component.scss']
})
export class HelpMessageToCheckValueComponent implements OnChanges {
    @Input() data: any;
    @Input() value: string;
    @Input() message: string;
    public isShowMessage: boolean;

    constructor(
        private loaderService: LoaderService
    ) {
    }

    ngOnChanges(): void {
        if (!this.loaderService.loader) {
            if (this.value && this.value.length && !this.data || this.value && this.value.length && !this.data.length) {
                this.isShowMessage = true;
            } else if (!this.value || !this.value.length) {
                this.isShowMessage = false;
            } else if (this.data && this.data.length) {
                this.isShowMessage = false;
            }
        }
    }
}

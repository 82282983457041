import {Component, OnInit} from '@angular/core';
import {NotificationsService} from '../../services/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    constructor(
        public notificationsService: NotificationsService
    ) {
    }

    ngOnInit() {
    }

    public close(): void {
        this.notificationsService.showNotify = false;
    }
}

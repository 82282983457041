import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'changeValue'
})
export class ChangeValuePipe implements PipeTransform {

    transform(value: any, ...args: any): any {
        if (value.toString().toLowerCase() === 'loggined') {
            return 'SIGNED_IN';
        } else {
            return value;
        }
    }
}

import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Pipe({
    name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

    constructor(
        private http: HttpClient
    ) {
    }

    async transform(src: string): Promise<string> {
        if (src) {
            const headers = new HttpHeaders({
                'custom-header': 'Biteeu admin back'
            });

            const reader = new FileReader();
            const imageBlob = await this.http.get(`https://${src}`, {
                headers,
                responseType: 'blob'
            }).toPromise();

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result as string);
                reader.readAsDataURL(imageBlob);
            });
        }
    }
}

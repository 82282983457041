import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    public canActivate(): boolean {
        const token = this.authService.loggedIn;

        if (!token) {
            this.router.navigate(['login']);
            return false;
        } else {
            return true;
        }
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkingForEmptyValue'
})
export class CheckingForEmptyValuePipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return value ? value : '-';
    }
}

import {Injectable} from '@angular/core';
import {TOKEN_NAME} from '../../shared/constants/config';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  public getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  public setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  public deleteToken(): void {
    localStorage.removeItem(TOKEN_NAME);
  }
}

import {Component, OnInit} from '@angular/core';
import {IS_SUPER} from '../../shared/constants/config';
import {CommonService} from '../../services/common.service';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    providers: [CommonService]
})
export class SideNavComponent implements OnInit {
    public isSuperAdmin: boolean = false;

    constructor(
        private commonService: CommonService
    ) {
    }

    ngOnInit() {
        this.isSuperAdmin = localStorage.getItem(IS_SUPER) === 'superAdmin';
    }

    public hideTab(name: string): boolean {
        return this.commonService.checkPermission(name);
    }
}

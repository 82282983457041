import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {JwtService} from './jwt.service';
import {Router} from '@angular/router';
import {IS_SUPER, PERMISSIONS} from '../../shared/constants/config';
import {environment} from '../../../environments/environment';
import {Login} from '../../core/models/login/login';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private jwtService: JwtService,
        private router: Router,
    ) {
    }

    public get loggedIn(): boolean {
        return !!this.jwtService.getToken();
    }

    public login(body): Observable<Login> {
        const url = `${environment.apiUrl}/auth/login`;
        return this.http
            .post<Login>(url, body)
            .pipe(
                map(response => {
                    if (response.data) {
                        this.jwtService.setToken('Bearer ' + response.data.token);
                        response.data.isSuper ? localStorage.setItem(IS_SUPER, 'superAdmin') : localStorage.setItem(IS_SUPER, 'admin');
                        localStorage.setItem(PERMISSIONS, JSON.stringify(response.data.permissions || []));
                    }
                    return response;
                })
            );
    }

    public logout(): void {
        const url = `${environment.apiUrl}/auth/logout`;
        this.http.post(url, '')
            .subscribe((response) => {
                this.jwtService.deleteToken();
                localStorage.removeItem(IS_SUPER);
                localStorage.removeItem(PERMISSIONS);
                this.router.navigate(['login']);
            });
    }
}

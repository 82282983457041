import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {TokenInterceptor} from './services/interceptors/token.interceptor';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {RouterModule} from '@angular/router';
import {LoginModule} from './modules/login/login.module';
import {MainPageComponent} from './components/main-page/main-page.component';

@NgModule({
    imports: [
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        SharedModule,
        LoginModule
    ],
    declarations: [
        AppComponent,
        MainPageComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import {Component, OnInit} from '@angular/core';
import {IS_SUPER} from '../../shared/constants/config';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {UnSubscribeComponent} from '../../shared/unsubscribe-component';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent extends UnSubscribeComponent implements OnInit {

    constructor(
        private router: Router,
    ) {
        super();
    }

    ngOnInit() {
        const userRole = localStorage.getItem(IS_SUPER);
        this.redirect(this.router.url, userRole);

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.unsubscribe$))
            .subscribe(({url}: NavigationEnd) => {
                this.redirect(url, userRole);
            });
    }

    private redirect(url: string, userRole: string) {
        if (url === '/' && userRole === 'admin') {
            this.router.navigate(['admin']);
        } else if (url === '/' && userRole === 'superAdmin') {
            this.router.navigate(['super-admin']);
        }
    }
}
